import React, { useState, useEffect } from 'react';
import Button from '../Button';
import HeaderStyle from './style';
import './blur.css';

const Header = () => {
  const [top, setTop] = useState(true);

  useEffect(() => {
    const scrollHandler = () => {
      setTop(window.pageYOffset <= 20);
    };
    window.addEventListener('scroll', scrollHandler);

    // Explicit call so that the navbar gets blurred when component mounts
    scrollHandler();

    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, []);

  return (
    <HeaderStyle className={`${!top && 'navbar-blur'}`}>
      <h1>Spin</h1>
      <div className="button-container">
        <Button />
      </div>
    </HeaderStyle>
  );
};

export default Header;
