import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from '../pages/Landing';
import ViewSpins from '../pages/ViewSpin';
import ScrollToTop from '../scrollToTop';

import GlobalStyles from './Global';

function App() {
  return (
    <>
      <GlobalStyles />
      <Router>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/view-spin" element={<ViewSpins />} />
          </Routes>
        </ScrollToTop>
      </Router>
    </>
  );
}

export default App;
