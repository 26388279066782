import styled from 'styled-components';
import { apercuPro, inter, romanaBT, textColor } from '../../theme';

const SpinItemStyle = styled.div`
  width: 285px;
  margin: 0 5px 0 5px;

  & .poster-img {
    height: 350px;
    width: 270px;
    mix-blend-mode: luminosity;
  }

  & .text-section {
    text-align: center;

    & .h2-section {
      height: 80px;
    }

    h2 {
      color: ${textColor[200]};
      font-family: ${romanaBT};
      font-size: 2.8rem;
      font-weight: 300;
    }

    h5 {
      color: ${textColor[200]};
      font-family: ${inter};
      font-size: 0.75rem;
      font-weight: 300;

      & .circle {
        font-size: 1rem;
        margin: 0 5px 0 5px;
      }
    }

    & .stars {
      margin-right: auto;
      margin-left: auto;
      width: 110px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    h6 {
      color: ${textColor[200]};
      font-family: ${inter};
      font-size: 0.75rem;
      font-weight: 500;
    }

    a {
      color: ${textColor[300]};
      font-family: ${apercuPro};
      font-size: 1.5rem;
      font-weight: 500;
    }
  }
`;

export default SpinItemStyle;
