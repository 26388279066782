// import React, { useEffect, useState } from 'react';
import Header from '../../components/Header';
import LandingPageStyle from './style';
import BackgroundImage from '../../assets/images/bg-img.webp';
import Spacer from '../../utils/spacer';
import Textfield from '../../components/Textfield';
import SpinItem from '../../components/SpinItem';
// import SpinItem from '../../components/SpinItem';
// import axios from 'axios';
// import { popularMovies, returnMovieOptions } from '../../providers/movies';

const LandingPage = () => {
  // const [movies, setMovies] = useState([]);

  // useEffect(() => {
  //   axios.request(popularMovies).then(response => {
  //     //Full movie list
  //     let data = response.data;

  //     var moviesData = data.slice(0, 20);

  //     //Top 4 movies
  //     let moviesRaw = [];

  //     // let movies = moviesRaw.filter(element => element.titleType === 'movie');

  //     moviesData.forEach(function (element) {
  //       console.log('before: ', element);
  //       var titleName = element.substring(7, element.length - 1);
  //       console.log('after: ', titleName);

  //       // axios.request(returnMovieOptions(titleName)).then(response => {
  //       //   moviesRaw.push(element);
  //       //   // setMovies(prefMovies => [...prefMovies, response.data]);
  //       // });
  //     });

  //     console.log(moviesRaw);
  //     // Oluwaseun Akoyon
  //   });
  // }, []);

  return (
    <>
      <LandingPageStyle>
        <Header />
        <div className="main-section-mobile">
          <div className="top-container">
            <div className="header-text-section">
              <h1>Your go-to source for movie soundtracks</h1>
            </div>
            <Spacer axis="vertical" size={99} />
          </div>
          <div className="bottom-container">
            <div className="emailfield-section">
              <Textfield />
              <Spacer axis="vertical" size={22} />
              <h5>
                We will send you ads. That's the only way we can pay the bills.
                Cool and Necessary ads tho.
              </h5>
            </div>
          </div>
        </div>
        <div className="main-section">
          <div className="left-container">
            <div className="header-text-section">
              <h1>Your go-to source for movie soundtracks</h1>
            </div>
            <Spacer axis="vertical" size={99} />
            <div className="emailfield-section">
              <Textfield />
              <Spacer axis="vertical" size={22} />
              <h5>
                We will send you ads. That's the only way we can pay the bills.
                Cool and Necessary ads tho.
              </h5>
            </div>
          </div>

          <div className="right-container">
            <img
              className="bg-image"
              src={BackgroundImage}
              alt="Person holding a phone with Spotify open."
            />
          </div>
        </div>
        <Spacer axis="vertical" size={103} />
        <div className="main-text-section">
          <h2>
            With Spin, no more pausing or rewinding to Shazam that perfect song
            from your film or Show.
          </h2>
        </div>
        <Spacer axis="vertical" size={61} />

        <div className="spin-poster-section">
          <SpinItem />
          <SpinItem />
          <SpinItem />
          <SpinItem />
          {/* {movies.map(movie => (
            <SpinItem
              key={movie.id}
              name={movie.title}
              posterImg={movie.image.url}
              year={movie.year}
            />
          ))} */}
        </div>

        <Spacer axis="vertical" size={134} />

        <div className="footer-section">
          <div className="emailfield-section">
            <Textfield />
            <Spacer axis="vertical" size={22} />
            <h5>
              We will send you ads. That's the only way we can pay the bills.
              Cool and Necessary ads tho.
            </h5>
          </div>
          <Spacer axis="vertical" size={75} />
          <h4 className="footer-text">
            © All Rights Reserved. Oreva, The Creator
          </h4>
          <Spacer axis="vertical" size={75} />
        </div>
      </LandingPageStyle>
    </>
  );
};

export default LandingPage;
