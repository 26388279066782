import { createGlobalStyle } from 'styled-components';
import { primaryFont, backgroundColor } from '../theme';

const GlobalStyles = createGlobalStyle`
    html {
        background:${backgroundColor[100]};
    }
    *, *:before, *:after {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        /* -webkit-transition: all 0.30s ease-in-out;
        -moz-transition: all 0.30s ease-in-out;
        -ms-transition: all 0.30s ease-in-out;
        -o-transition: all 0.30s ease-in-out; */
    }
    a {
        text-decoration: none;
    }
    body {
        background: ${backgroundColor[100]};
        transition: all 0.05s linear;
        font-family: ${primaryFont};
        font-style: normal;
        font-weight: 400;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
`;

export default GlobalStyles;
