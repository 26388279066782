import styled from 'styled-components';
import { textColor, workSans, primaryColor } from '../../theme';

const ButtonStyle = styled.button`
  color: ${textColor[100]};
  cursor: pointer;
  height: 38px;
  width: 150px;
  border-radius: 100px;
  font-size: 1rem;
  font-family: ${workSans};
  font-weight: bold;
  outline: none;
  background-color: transparent;
  border: 1.35px solid ${primaryColor[100]};
`;

export default ButtonStyle;
