import React from 'react';
import Header from '../../components/Header';
import ViewSpinStyle from './style';
import Spacer from '../../utils/spacer';
import Textfield from '../../components/Textfield';
import CopyIcon from '../../assets/images/copy.svg';
import SpotifyIcon from '../../assets/images/spotify.png';
import Poster from '../../assets/images/poster.webp';
import Star from '../../assets/images/star.svg';
import PlayerComp1 from '../../assets/images/playerComp.png';
import PlayerComp2 from '../../assets/images/playerComp2.png';

const ViewSpins = () => {
  return (
    <ViewSpinStyle>
      <Header />

      <div className="main-section">
        <div className="left-container">
          <img className="movie-poster" src={Poster} alt="Movie poster" />
        </div>

        <div className="right-container">
          <div className="text-section">
            <h2>Star Wars</h2>

            <Spacer axis="vertical" size={16} />
            <h5>
              2018 <span className="circle">&#9679;</span> Action{'   '}
              <span className="circle">&#9679;</span> 2h 31m
            </h5>
            <Spacer axis="vertical" size={10} />
            <div className="stars">
              <img src={Star} alt="star" />
              <img src={Star} alt="star" />
              <img src={Star} alt="star" />
              <img src={Star} alt="star" />
              <img src={Star} alt="star" />
            </div>
            <Spacer axis="vertical" size={22} />
            <h6>
              The Star Wars Trilogy Special Edition was a theatrical anniversary
              edition of the original trilogy, in order to celebrate the 20th
              anniversary of the release of Star Wars: Episode IV A New Hope.
            </h6>
            <Spacer axis="vertical" size={65} />
            <h4>
              As Seen on <span>NETFLIX</span>
            </h4>
          </div>
        </div>
      </div>

      <Spacer axis="vertical" size={76} />

      <div className="soundtracks-section">
        <h6>Streaming soundtracks, curated by Spin</h6>

        <div>
          <img src={PlayerComp1} alt="Player composition" />
          <br />
          <Spacer axis="vertical" size={20} />

          <img src={PlayerComp2} alt="Player composition1" />
        </div>
      </div>

      <Spacer axis="vertical" size={49} />

      <div className="get-playlist-section">
        <h6>Get Playlist</h6>

        <Spacer axis="vertical" size={30} />

        <div className="services">
          <img src={SpotifyIcon} height={50} width={50} alt="Spotify" />
          <Spacer axis="horizontal" size={22} />
          <img src={SpotifyIcon} height={50} width={50} alt="Apple music" />
          <Spacer axis="horizontal" size={22} />
          <img src={SpotifyIcon} height={50} width={50} alt="Youtube music" />
        </div>
      </div>

      <Spacer axis="vertical" size={73} />

      <div className="share-link-section">
        <h6>
          Share link - <span>spin.plalylistimade.com</span>
        </h6>
        <Spacer axis="horizontal" size={7} />
        <img src={CopyIcon} height={20} width={20} alt="Copy" />
      </div>

      <Spacer axis="vertical" size={150} />
      <div className="footer-section">
        <div className="emailfield-section">
          <Textfield hint="Send Playlist to my email" />
          <Spacer axis="vertical" size={22} />
          <h5>
            We will send you ads. That's the only way we can pay the bills. Cool
            and Necessary ads tho.
          </h5>
        </div>
        <Spacer axis="vertical" size={75} />
        <h4 className="footer-text">
          © All Rights Reserved. Oreva, The Creator
        </h4>
        <Spacer axis="vertical" size={75} />
      </div>
    </ViewSpinStyle>
  );
};

export default ViewSpins;
