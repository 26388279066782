import styled from 'styled-components';
import { textColor } from '../../theme';

const HeaderStyle = styled.section`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  z-index: 1;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 28px;
  padding-bottom: 20px;

  h1 {
    font-size: 3.313rem;
    color: ${textColor[100]};

    @media only screen and (max-width: 500px) {
      font-size: 3rem;
    }
  }

  & .button-container {
    @media only screen and (max-width: 500px) {
      display: none;
    }
  }

  @media only screen and (max-width: 500px) {
    justify-content: start;
  }
`;

export default HeaderStyle;
