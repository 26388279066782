export const backgroundColor = {
  100: '#161517',
};

export const primaryColor = {
  100: '#ffffff',
};

export const textColor = {
  100: '#ffffff',
  200: '#e2e2e2',
  300: '#b45353',
  400: '#808192',
};
