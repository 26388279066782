import styled from 'styled-components';
import {
  textColor,
  apercu,
  workSans,
  apercuPro,
  romanaBT,
  inter,
} from '../../theme';

const ViewSpinStyle = styled.div`
  width: 100vw;
  height: 100%;
  background-image: url('/img/header2.webp');
  background-position: 0px 140px;
  background-repeat: repeat-x;
  background-attachment: scroll;
  background-size: cover;
  background-color: #0f0e10;

  & .main-section {
    padding-top: 160px;
    display: flex;

    & .left-container {
      width: 41%;
      height: 100%;

      & .movie-poster {
        float: right;
        mix-blend-mode: luminosity;
        width: 492px;
        height: auto;
        max-height: 651px;
      }

      @media only screen and (max-width: 700px) {
        padding-right: 30px;
      }
    }
    & .right-container {
      width: 59%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 170px;

      & .text-section {
        width: 290px;
        text-align: center;

        h2 {
          color: ${textColor[200]};
          font-family: ${romanaBT};
          font-size: 2.8rem;
          font-weight: 300;
        }

        h5 {
          color: ${textColor[200]};
          font-family: ${inter};
          font-size: 0.75rem;
          font-weight: 300;

          & .circle {
            font-size: 1rem;
            margin: 0 5px 0 5px;
          }
        }

        & .stars {
          margin-right: auto;
          margin-left: auto;
          width: 110px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        h6 {
          color: ${textColor[200]};
          font-family: ${inter};
          font-size: 0.75rem;
          font-weight: 500;
        }

        h4 {
          letter-spacing: 0.07em;
          color: #24282e;
          font-weight: 800;
          font-size: 1rem;
          font-family: ${workSans};

          span {
            color: #ff2000;
          }
        }
      }
      @media only screen and (max-width: 700px) {
        padding-right: 30px;
      }
    }
  }

  & .soundtracks-section {
    text-align: center;

    h6 {
      font-family: ${apercuPro};
      font-style: normal;
      font-weight: 700;
      font-size: 1.563rem;
      color: ${textColor[400]};
      margin-bottom: 92px;

      @media only screen and (max-width: 700px) {
        margin-bottom: 40px;
        font-size: 1.2rem;
      }
    }

    img {
      width: 87%;
      max-width: 540px;
      height: auto;
      max-height: 169px;
    }
  }

  & .get-playlist-section {
    text-align: center;

    h6 {
      font-family: ${apercuPro};
      font-style: normal;
      font-weight: 700;
      font-size: 1.438rem;
      color: ${textColor[100]};
    }

    & .services {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        cursor: pointer;
      }
    }
  }

  & .share-link-section {
    display: flex;
    justify-content: center;
    align-items: center;

    h6 {
      font-family: ${apercuPro};
      font-style: normal;
      font-weight: 700;
      font-size: 1.438rem;
      color: ${textColor[100]};

      span {
        cursor: pointer;
        text-decoration: underline;
      }

      @media only screen and (max-width: 700px) {
        font-size: 1.2rem;
      }
    }

    img {
      cursor: pointer;
    }
  }

  & .footer-section {
    & .emailfield-section {
      margin-left: auto;
      margin-right: auto;
      width: 780px;

      h5 {
        color: rgba(255, 255, 255, 0.6);
        font-size: 1.6rem;
        font-weight: 300;
        font-family: ${apercu};
      }

      @media only screen and (max-width: 700px) {
        width: 83%;

        h5 {
          font-size: 1rem;
        }
      }
    }

    & .footer-text {
      text-align: center;
      font-size: 1.25rem;
      font-weight: 300;
      font-family: ${workSans};
      color: rgba(255, 255, 255, 0.5);

      @media only screen and (max-width: 500px) {
        font-size: 0.65rem;
      }
    }
  }
`;

export default ViewSpinStyle;
