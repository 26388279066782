import React from 'react';
import { Link } from 'react-router-dom';
import SpinItemStyle from './style';
import Star from '../../assets/images/star.svg';
import Spacer from '../../utils/spacer';
import Poster from '../../assets/images/poster.webp';

const SpinItem = () => {
  return (
    <SpinItemStyle>
      <img className="poster-img" src={Poster} alt="Poster" />
      <Spacer axis="vertical" size={20} />
      <div className="text-section">
        <div className="h2-section">
          <h2>Star Wars</h2>
        </div>

        <Spacer axis="vertical" size={16} />
        <h5>
          1999 <span className="circle">&#9679;</span> Action{'   '}
          <span className="circle">&#9679;</span> 2h 31m
        </h5>
        <Spacer axis="vertical" size={10} />
        <div className="stars">
          <img src={Star} alt="star" />
          <img src={Star} alt="star" />
          <img src={Star} alt="star" />
          <img src={Star} alt="star" />
          <img src={Star} alt="star" />
        </div>
        <Spacer axis="vertical" size={22} />
        <h6>
          The Star Wars Trilogy Special Edition was a theatrical anniversary
          edition of the original trilogy, in order to celebrate the 20th
          anniversary of the release of Star Wars: Episode IV A New Hope.
        </h6>
        <Spacer axis="vertical" size={29} />
        <Link to={`view-spin`}>View Spins</Link>
      </div>
    </SpinItemStyle>
  );
};
// const SpinItem = ({ name, posterImg, year }) => {
//   return (
//     <SpinItemStyle>
//       <img className="poster-img" src={posterImg} alt="Poster" />
//       <Spacer axis="vertical" size={20} />
//       <div className="text-section">
//         <div className="h2-section">
//           <h2>{name}</h2>
//         </div>

//         <Spacer axis="vertical" size={16} />
//         <h5>
//           {year} <span className="circle">&#9679;</span> Action{'   '}
//           <span className="circle">&#9679;</span> 2h 31m
//         </h5>
//         <Spacer axis="vertical" size={10} />
//         <div className="stars">
//           <img src={Star} alt="star" />
//           <img src={Star} alt="star" />
//           <img src={Star} alt="star" />
//           <img src={Star} alt="star" />
//           <img src={Star} alt="star" />
//         </div>
//         <Spacer axis="vertical" size={22} />
//         <h6>
//           The Star Wars Trilogy Special Edition was a theatrical anniversary
//           edition of the original trilogy, in order to celebrate the 20th
//           anniversary of the release of Star Wars: Episode IV A New Hope.
//         </h6>
//         <Spacer axis="vertical" size={29} />
//         <a href="#">View Spins</a>
//       </div>
//     </SpinItemStyle>
//   );
// };

export default SpinItem;
