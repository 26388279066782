import styled from 'styled-components';
import { textColor, primaryFont, primaryColor } from '../../theme';

const TextfieldContainerStyle = styled.div`
  display: flex;
  width: 100%;
  border: none;
  border-bottom: 2.5px solid rgba(255, 255, 255, 0.6);
  &:focus {
    border-bottom: 2.5px solid ${primaryColor[100]};
  }
`;

const TextfieldStyle = styled.input`
  height: 4.7rem;
  width: 100%;
  color: ${textColor[100]};
  background-color: transparent;
  font-size: 2.4rem;
  font-family: ${primaryFont};
  border: none;
  padding-right: 5px;
  outline: 0;

  ::placeholder {
    color: rgba(255, 255, 255, 0.6);
  }

  @media only screen and (max-width: 600px) {
    font-size: 1rem;
  }
`;

export { TextfieldStyle, TextfieldContainerStyle };
