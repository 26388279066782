import styled from 'styled-components';
import { textColor, apercu, workSans } from '../../theme';

const LandingPageStyle = styled.div`
  width: 100vw;
  height: 100%;
  background-image: url('/img/header.webp');
  background-attachment: scroll;
  background-size: cover;

  & .main-section-mobile {
    padding-top: 150px;
    display: none;

    & .top-container {
      padding-top: 50px;

      & .header-text-section {
        width: 400px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        h1 {
          font-size: 2.625rem;
          color: ${textColor[100]};
        }

        @media only screen and (max-width: 400px) {
          h1 {
            font-size: 2rem;
            margin-right: 20px;
            margin-left: 20px;
          }
        }
      }
    }

    & .bottom-container {
      & .emailfield-section {
        margin-left: auto;
        margin-right: auto;
        width: 410px;

        h5 {
          color: rgba(255, 255, 255, 0.6);
          font-size: 1.25rem;
          font-weight: 300;
          font-family: ${apercu};
        }

        @media only screen and (max-width: 500px) {
          width: 85%;

          h5 {
            font-size: 1rem;
          }
        }
      }
    }

    @media only screen and (max-width: 800px) {
      display: block;
    }
  }

  & .main-section {
    padding-top: 150px;
    display: flex;

    & .left-container {
      width: 40%;
      height: 100%;
      padding-top: 50px;

      & .header-text-section {
        width: 400px;
        text-align: right;
        margin-left: auto;
        h1 {
          font-size: 2.625rem;
          color: ${textColor[100]};
        }
      }

      & .emailfield-section {
        margin-left: auto;
        width: 410px;

        h5 {
          color: rgba(255, 255, 255, 0.6);
          font-size: 1.25rem;
          font-weight: 300;
          font-family: ${apercu};
        }

        @media screen and (max-width: 1100px) and (min-width: 800px) {
          margin-left: 20px;
        }
      }
    }

    & .right-container {
      width: 60%;
      height: 100%;

      & .bg-image {
        float: right;
        width: 87%;
        max-width: 800px;
        height: auto;
        max-height: 530px;
      }
    }

    @media only screen and (max-width: 800px) {
      display: none !important;
    }
  }

  & .main-text-section {
    text-align: center;
    padding: 0 77px 0 77px;
    h2 {
      font-size: 1.8rem;
      color: ${textColor[100]};
    }

    @media only screen and (max-width: 400px) {
      padding: 0 30px 0 30px;
    }
  }

  & .spin-poster-section {
    display: flex;
    justify-content: center;
    align-items: center;
    scrollbar-width: none;
    -ms-overflow-style: none;

    @media only screen and (max-width: 1100px) {
      justify-content: start;
      margin-left: 20px;
      margin-right: 20px;
      overflow-x: auto;
    }
  }

  & .spin-poster-section::-webkit-scrollbar {
    display: none;
  }

  & .footer-section {
    & .emailfield-section {
      margin-left: auto;
      margin-right: auto;
      width: 780px;

      h5 {
        color: rgba(255, 255, 255, 0.6);
        font-size: 1.6rem;
        font-weight: 300;
        font-family: ${apercu};
      }

      @media only screen and (max-width: 1000px) {
        display: none;
      }
    }

    & .footer-text {
      text-align: center;
      font-size: 1.25rem;
      font-weight: 300;
      font-family: ${workSans};
      color: rgba(255, 255, 255, 0.5);

      @media only screen and (max-width: 500px) {
        font-size: 0.65rem;
      }
    }
  }
`;

export default LandingPageStyle;
