import React from 'react';
import { TextfieldContainerStyle, TextfieldStyle } from './style';
import RightChevron from '../../assets/images/right-chevron.svg';

const Textfield = ({ hint = 'your email' }) => {
  return (
    <TextfieldContainerStyle>
      <TextfieldStyle placeholder={hint}></TextfieldStyle>
      <img src={RightChevron} alt="Right Arrow" />
    </TextfieldContainerStyle>
  );
};

export default Textfield;
